<template>
  <div class="log-header">
    <div class="head-title" v-show="props.showTitle">
      <div class="icon" @click="back">返回</div>
      <div class="title">{{ state.title }}</div>
    </div>
    <div class="head-content" :style="contentBoxStyle">
      <div class="item" v-for="(item, index) in props.contentList" :key="index">
        <div class="label">{{ item.label }}</div>
        <div
          v-if="item.overflow ? item.value.length < item.len : true"
          :class="['value', item.overflow && 'overflow-e']"
          :style="{ 'max-width': item.maxWidth + 'px' }"
        >
          {{ item.value }}
        </div>
        <a-tooltip placement="bottomLeft" v-else overlayClassName="lang-name-pop">
          <template #title>
            <span>{{ item.value }}</span>
          </template>
          <div :class="['value', item.overflow && 'overflow-e']" :style="{ 'max-width': item.maxWidth + 'px' }">
            {{ item.value }}
          </div>
        </a-tooltip>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, defineProps } from 'vue'
import { useRouter } from 'vue-router'
const props = defineProps({
  contentList: {
    type: Array,
    default: () => []
  },
  showTitle: {
    type: Boolean,
    default: true
  },
  contentBoxStyle: {
    type: Object || String,
    default: () => ({})
  }
})
const state = reactive({
  title: '操作内容'
})

const router = useRouter()

const back = () => {
  router.back()
}
</script>
<style lang="less" scoped>
@import url(./index.less);
</style>
